import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
	{
		path: '/',
		name: 'Home',
		meta: { title: "TBL Systems, Inc." },
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import(/* webpackChunkName: "about" */ '../views/Blocks.vue')
	},

	{
		path: '/blogs',
		name: 'Blog',
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import(/* webpackChunkName: "blog" */ '../views/Blogger.vue')
	},
		

	{
		path: '/blog/:id',
		name: 'Blog',
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import(/* webpackChunkName: "blog" */ '../views/Blog.vue')
	},

	{
		path: '/edit/:id',
		name: 'Blog Edit',
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import(/* webpackChunkName: "edit" */ '../views/Edit.vue')
	},
		
	{
		path: '/blogger/:id',
		name: 'Blogger',
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import(/* webpackChunkName: "Blogger" */ '../views/Blogger.vue')
	},

	{
		path: '/news',
		name: 'News',
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import(/* webpackChunkName: "news" */ '../views/Blogger.vue')
	},

	{
		path: '/reporter/:id',
		name: 'Reporter',
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import(/* webpackChunkName: "Reporter" */ '../views/Blogger.vue')
	},

	{
		path: '/article/:id',
		name: 'News Article',
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import(/* webpackChunkName: "article" */ '../views/Blog.vue')
	},

	{
		path: '/investor',
		name: 'Investmentor Updates',
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import(/* webpackChunkName: "investor" */ '../views/Blogger.vue')
	},

	{
		path: '/investor/:id',
		name: 'Investor',
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import(/* webpackChunkName: "Investor" */ '../views/Blog.vue')
	},

	{
		path: '/updater/:id',
		name: 'Updates',
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import(/* webpackChunkName: "updater" */ '../views/Blogger.vue')
	},

	{
		path: '/contact/:regarding',
		name: 'Contact',
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import(/* webpackChunkName: "Contact" */ '../views/Contact.vue')
	},

	{
		path: '/contact',
		name: 'Contact',
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import(/* webpackChunkName: "Contact" */ '../views/Contact.vue')
	},

	{
		path: '/messages',
		name: 'Messages',
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import(/* webpackChunkName: "Messages" */ '../views/Messages.vue')
	},

	{
		path: '/campaigns/all',
		name: 'Campaigns',
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import(/* webpackChunkName: "Campaigns" */ '../views/Messages.vue')
	},

	{
		path: '/campaigns/:id',
		name: 'Campaigns',
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import(/* webpackChunkName: "Campaigns" */ '../views/Messages.vue')
	},
	/*****************************/

	{
		path: '/careers',
		name: 'Careers',
		component: () => import(/* webpackChunkName: "about" */ '../views/Jobs.vue')
	},

	{
		path: '/about',
		name: 'About',
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		//component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
		component: () => import(/* webpackChunkName: "about" */ '../views/Blocks.vue')
	},
	{
		path: '/products',
		name: 'Products',
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import(/* webpackChunkName: "products" */ '../views/Products.vue')
	},
	{
		path: '/why',
		name: 'Why',
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import(/* webpackChunkName: "why" */ '../views/Why.vue')
	},
	{
		path: '/plans/:id',
		name: 'Plans',
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import(/* webpackChunkName: "plans" */ '../views/Plans.vue')
	},
	{
		path: '/service/:id',
		name: 'Service',
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import(/* webpackChunkName: "plans" */ '../views/Plans.vue')
	},
	{
		path: '/signin',
		name: 'Signin',
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import(/* webpackChunkName: "Signin" */ '../views/Signin.vue')
	},
	{
		path: '/support',
		name: 'Support',
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import(/* webpackChunkName: "support" */ '../views/Blocks.vue')
	},
	{
		path: '/editCampaign/:id',
		name: 'Campaign Edit',
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import(/* webpackChunkName: "campaign edit" */ '../views/EditCampaign.vue')
	},
	{
		path: '/buy_le/:regarding',
		name: '',
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import(/* webpackChunkName: "Buy" */ '../views/Buy.vue')
	},

	{
		path: '/buy_fire/:regarding',
		name: '',
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import(/* webpackChunkName: "Buy" */ '../views/Buy.vue')
	},

	{
		path: '/buy_muni/:regarding',
		name: '',
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import(/* webpackChunkName: "Buy" */ '../views/Buy.vue')
	},

	{
		path: '/buy_privsec/:regarding',
		name: '',
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import(/* webpackChunkName: "Buy" */ '../views/Buy.vue')
	},

	{
		path: '*',
		name: 'Page',
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import(/* webpackChunkName: "about" */ '../views/Blocks.vue')
	},
]

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes
})

export default router
